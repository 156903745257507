import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import imgPatagoniaTelebingo from "../img/LOGO_EV_21_TB_42.png";

const ModalAvisosGenerales = ({
  showModal,
  clickPataMinibingo,
  clickPataTeleBingo,
  clickTombolaExpress,
  clickTombola,
  logoTombola,
  logoTomboexpress,
  logoPatagoniaMiniBingo,
  logoPatagoniaTelebingo,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (showModal !== undefined) {
      handleShow();
    }
  }, [showModal]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleIrAlJuego = (juego) => {
    switch (juego) {
      case 0:
        clickTombola();
        break;
      case 1:
        clickTombolaExpress();
        break;
      case 20:
        clickPataMinibingo();
        break;
      case 21:
        clickPataTeleBingo();
        break;
      default:
        break;
    }
  };

  const handlelogo = (juego) => {
    switch (juego) {
      case 0:
        clickTombola();
        break;
      case 1:
        clickTombolaExpress();
        break;
      case 20:
        clickPataMinibingo();
        break;
      case 21:
        return imgPatagoniaTelebingo;
      default:
        break;
    }
  };

  return showModal !== undefined ? (
    <>
      {showModal.length > 0 ? (
        <Modal
          show={show}
          onHide={handleClose}
          className="ModalAvisosGenerales"
        >
          <Modal.Header
            closeButton
            className={"modalHeader-" + process.env.REACT_APP_COD_ORGANIZACION}
            id={"headerModal-" + process.env.REACT_APP_COD_ORGANIZACION}
          >
            <Modal.Title>
              <SpanTitulo>Avisos generales</SpanTitulo>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              {showModal.map((aviso) => (
                <>
                  <div
                    className="row justify-content-md-center"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className="col ContenidoModaleAvisosGenerales">
                      <ColCentrado>
                        <SpanTituloAviso>
                          Tenés {aviso.mensaje.replace("de", "!")}
                        </SpanTituloAviso>
                      </ColCentrado>
                      <ColCentrado>
                        <Logojuego
                          src={handlelogo(aviso.juego)}
                          alt=""
                          onClick={() => {
                            handleIrAlJuego(aviso.juego);
                          }}
                        />
                      </ColCentrado>
                      <br />
                      <ColCentrado>
                        <SpanFechaSorteo>{aviso.fechaSorteo}</SpanFechaSorteo>
                      </ColCentrado>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer
            className={"modalFooter-" + process.env.REACT_APP_COD_ORGANIZACION}
            id={"footerModal-" + process.env.REACT_APP_COD_ORGANIZACION}
          >
            <BotonCerrar
              className={
                "btn bg-prov-" + process.env.REACT_APP_COD_ORGANIZACION
              }
              onClick={handleClose}
            >
              Cerrar
            </BotonCerrar>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default ModalAvisosGenerales;

const BotonJuego = styled.button`
  width: 12vh;
  border-radius: 100%;
  height: 12vh;
`;
const Logojuego = styled.img`
  width: 70%;
`;
const BotonCerrar = styled.button`
  width: 100%;
  align-self: center;
  font-family: "Mont-Heavy";
`;

const SpanTituloAviso = styled.span`
  background-color: #28a745;
  text-align: center;
  color: aliceblue;
  font-family: "Mont-Heavy";
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 8px;
  font-size: 3vh;
`;

const SpanFechaSorteo = styled.span`
  font-family: "Mont-Heavy";
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
`;

const SpanTitulo = styled.span`
  font-family: "Mont-Heavy";
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
`;

const ColCentrado = styled.div`
  text-align: -webkit-center;
`;
