import React from "react";

class JuegoResponsable extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="p-1 mx-auto">
        <h5 className="text-center mb-3">NUESTRO COMPROMISO</h5>

        <p className="text-small text-justify">
          Nuestra intención desde LotLine es que te diviertas de forma sana,
          jugando moderadamente y conociendo tus límites y creemos que somos la
          elección adecuada para ello. Por eso estamos comprometidos con el
          "Juego Responsable" con el cual trabajamos para cumplir con dos
          acciones fundamentales para promover una saludable diversión y un sano
          entretenimiento:
          <br></br>
          Difundir el concepto de Juego Responsable. Contribuir a la detección
          precoz y orientación adecuada de las personas que padecen problemas
          con el juego.
          <br></br>
          Estos objetivos contribuyen al cumplimiento de dos principios
          fundamentales de la Salud Pública, Prevención Primaria promoción de la
          salud y del Juego Responsable y Prevención Secundaria detección precoz
          y reducción del daño en personas con problemas de juego y en
          familiares. Estamos convencidos que un mayor conocimiento acerca del
          juego contribuirá a que tu práctica sea segura y divertida. Es por
          ello que te invitamos a disfrutar sanamente todas las alternativas de
          entretenimiento que te ofrece LotLine.
          <br></br>
          Para ayudarte, te ofrecemos una serie de consejos y opciones para tu
          cuenta que te ayudarán a gestionar tu juego. Estos te proporcionarán
          control sobre el tiempo y el dinero que inviertes en jugar.
        </p>

        <ul>
          <li>Cómo mantener el control</li>
          <li>Límites de ingreso</li>
          <li>
            {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
              ? "Autolimitación"
              : "Autoexclusión"}
          </li>
        </ul>
        <p className="text-justify text-small">
          Si te preocupa tu actividad de juego, puedes ingresar aquí para
          realizar una autoevaluación sobre problemas con el juego que te
          ayudará a saber si es posible que tengas un problema.
          <br></br>
          Si respondes "si" a 7 de las 20 preguntas, puedes tener un problema
          con tu manera de jugar y necesitas ayuda. En ese caso, te recomendamos
          que hables con alguien sobre tus respuestas, te pongas en contacto con
          nuestros consejeros de Juego Responsable en
          www.juegoresponsable.com.ar o a través de una llamada gratuita y
          confidencial en el <b>0800-333-0333</b> para ser orientado e
          informados, sobre problemas con el juego. También te recomendamos que
          consideres{" "}
          {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
            ? "autolimitarte"
            : "autoexcluirte"}
          .
        </p>

        <h5 className="text-center mb-3 mt-3">MANTENER EL CONTROL</h5>
        <div className="text-small text-justify">
          Creemos que el juego siempre debe ser una actividad de ocio que puedas
          disfrutar. Recuerda estos sencillos consejos para asegurarte de que el
          juego no se convierte en un problema.
          <ol>
            <li>
              Apostar debe de ser un entretenimiento y no una forma de ganar
              dinero.
            </li>
            <li>
              Apuesta con consideración y nunca trates de recuperar las
              pérdidas.
            </li>
            <li>
              Sólo apuesta el dinero que te puedes permitir perder. Utiliza los
              límites de ingreso para gestionar la cantidad de dinero que
              gastas.
            </li>
            <li>Controla el tiempo que inviertes en jugar.</li>
            <li>
              Combina el juego con otras actividades. Si el juego es tu única
              forma de entretenimiento, considerá si aún te estás divirtiendo.
            </li>
            <li>
              Tomá descansos del juego con frecuencia. El juego continuado puede
              hacerte perder el control sobre el tiempo y la perspectiva.
            </li>
            <li>
              No apuestes bajo los efectos del alcohol o cuando estés enfadado o
              deprimido.
            </li>
            <li>
              Piensa en cuánto dinero has gastado en el juego. Puedes ver tu
              actividad en línea en el historial de tu cuenta.
            </li>
            <li>
              Si necesitás hablar con alguien sobre problemas con el juego,
              ponete en contacto con especialistas que podrán ayudarte al
              0800-333-0333.
            </li>
          </ol>
        </div>
        <h5 className="text-center mb-3 mt-3">GESTIÓN DE LÍMITES</h5>
        <p className="text-small text-justify">
          Límites de ingreso
          <br></br>
          Te recomendamos que establezcas un límite de ingreso para gestionar la
          cantidad de dinero que puedes ingresar en tu cuenta. Una vez logueado,
          ingresá en /micuenta/cajero/mis-limites. Podrás reducir tus límites de
          ingreso en cualquier momento y los cambios se aplicarán de forma
          inmediata.
          <br></br>
          Antes de poder incrementar tus límites de ingreso, considera
          cuidadosamente si puedes permitírtelo. Nunca decidas incrementar tus
          límites porque has perdido dinero y crees que lo recuperarás apostando
          más.
        </p>
        <p className="text-small text-justify">
          Para Mayor información, visite el sitio de{" "}
          <a
            className="text-info font-weight-bold"
            href="http://www.juegoresponsable.com.ar/"
          >
            Juego Responsable
          </a>
        </p>
      </div>
    );
  }
}

export default JuegoResponsable;
