import React from "react";
import { withRouter } from "react-router";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarrouselPublicidad = () => {
  return (
    <Carousel
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      autoPlay={true}
      infiniteLoop={true}
      interval={4200}
      transitionTime={1000}
    >
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <img
          src={
            require("./img/PUBLI_1_ORG_" +
              process.env.REACT_APP_COD_ORGANIZACION +
              ".png").default
          }
          className="publicidadImg"
        />
      </div>
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <img
          src={
            require("./img/PUBLI_2_ORG_" +
              process.env.REACT_APP_COD_ORGANIZACION +
              ".png").default
          }
          className="publicidadImg"
        />
      </div>

      {/* <div style={{cursor:"pointer", backgroundColor:"lightgrey"}} data-toggle="modal" data-target="#rateModal"><img src={require("./img/PUBLI_2_ORG_"+process.env.REACT_APP_COD_ORGANIZACION+".png").default} className="publicidadImg"/></div> */}
    </Carousel>
  );
};

export default withRouter(CarrouselPublicidad);
