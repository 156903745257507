import React, { useState } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { estilosProv } from "../../coloresProvincias";
import $ from "jquery";

const LlamadorJuego = ({
  enableJuego,
  clickJuego,
  cantCuponesJuego,
  logoJuego,
  nombreJuego,
  promocion,
  estadoPlataforma,
  urljuego,
  propSide,
  tieneCuil,
}) => {
  const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
  const fondoLlamador = estilosProv[codOrg].fondoLlamador;
  const fondo = estilosProv[codOrg].fondo;
  const gradiente1Llamador = estilosProv[codOrg].gradiente1Llamador;
  const gradiente2Llamador = estilosProv[codOrg].gradiente2Llamador;

  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
  };

  const linkAJuego = () => {
    if (
      [
        "quinielatradicional",
        "quinielainstantanea",
        "tombola",
        "quiniexpress",
        "tomboexpress",
      ].includes(urljuego.toLowerCase())
    ) {
      return "/juego/" + urljuego;
    }
    if (["patagoniaminibingo", "patagoniatelebingo"].includes(urljuego)) {
      if (tieneCuil) {
        return "/juego/" + urljuego;
      }
    }
  };

  return (
    enableJuego === "true" &&
    (estadoPlataforma.acceptedTyc === true ||
      estadoPlataforma.acceptedTyc === null ||
      estadoPlataforma.acceptedTyc === undefined) &&
    (estadoPlataforma.autoexcluidoHasta === undefined ||
      estadoPlataforma.autoexcluidoHasta === null) && (
      <DivIconoLlamadorJuego
        className={propSide === undefined ? "col" : propSide}
      >
        <Animated
          animationIn="flipInY"
          style={{ cursor: "pointer" }}
          animationInDuration={1200}
        >
          <Animated
            animationIn="flipInY"
            animationInDuration={1200}
            isVisible={true}
          >
            <ContainerJuegos
              className="container"
              onClick={() => {
                testAnim();
                if (urljuego === "patagoniaminibingo") {
                  clickJuego("20");
                } else if (urljuego === "patagoniatelebingo") {
                  clickJuego("21");
                } else {
                  clickJuego();
                }
              }}
            >
              <Link to={linkAJuego}>
                <div className="row justify-content-center">
                  <FondoLogo
                    aplicarGradiente={false}
                    gradiente1Llamador={
                      urljuego.toLowerCase() === "tombola"
                        ? "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? "#ed3054"
                        : gradiente1Llamador
                    }
                    gradiente2Llamador={gradiente2Llamador}
                    colorFondo={
                      urljuego.toLowerCase() === "tombola"
                        ? "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? "#ed3054"
                        : fondoLlamador
                    }
                    className="col align-self-center"
                  >
                    {cantCuponesJuego > 0 ? (
                      <BadgeNotificacionesJuego className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle">
                        {cantCuponesJuego}
                      </BadgeNotificacionesJuego>
                    ) : (
                      <></>
                    )}
                    <LogoJuego src={logoJuego} alt="img" />
                  </FondoLogo>
                  <div className="col-11">
                    <NombreJuego>{nombreJuego}</NombreJuego>
                  </div>
                  <SpanPromociones>
                    {promocion !== undefined ? promocion : <></>}
                  </SpanPromociones>
                </div>
              </Link>
            </ContainerJuegos>
          </Animated>
        </Animated>
      </DivIconoLlamadorJuego>
    )
  );
};

export default LlamadorJuego;

const LogoJuego = styled.img`
  width: 11vh;
  margin-top: 2vh;
  margin-left: 0.5vh;
`;
const BadgeNotificacionesJuego = styled.span`
  background-color: #5a83bd;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  z-index: 3;
  width: 4vh;
  height: 4vh;
  text-align: center;
  padding-top: 0vh;
`;

const NombreJuego = styled.div`
  margin-top: 4vh;
  text-align: center;
`;

const FondoLogo = styled.div`
  background: radial-gradient(
    circle at 65% 15%,
    ${(props) => (props.aplicarGradiente ? "white" : props.gradiente1Llamador)}
      1px,
    ${(props) => props.gradiente1Llamador} 3%,
    ${(props) =>
        props.aplicarGradiente ? props.colorFondo : props.gradiente1Llamador}
      60%,
    ${(props) =>
        props.aplicarGradiente
          ? props.gradiente2Llamador
          : props.gradiente1Llamador}
      100%
  );
  min-height: 16vh;
  border-radius: 50%;
  min-width: 14vh;
  max-width: 16vh;
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px -5px 13px 4px #b0b7b3" : ""}
`;

const ContainerJuegos = styled.div`
  min-width: 15vh;
  margin-top: 8vh;
`;

const SpanPromociones = styled.span`
  background-color: #01c160;
  color: #f0f0f0;
  border-radius: 1em;
  position: absolute;
  top: 13.5vh;
  min-width: 8rem;
  text-align: center;
`;
const DivIconoLlamadorJuego = styled.div`
  min-width: 16vh;
`;
