import React from "react";
import { Animated } from "react-animated-css";
import { Button } from "reactstrap";
import $ from "jquery";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";

import DeviceOrientation, { Orientation } from "react-screen-orientation";
import { convertToObject } from "typescript";

// eslint-disable-next-line no-unused-vars
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const Autoexclusion = (props) => {
  const [excluido, setExcluido] = useState(false);
  const [indefinido, setIndefinido] = useState(false);
  const [diasExclusion, setDiasExclusion] = useState(0);
  const [fechaFin, setFechaFin] = useState(Date.now());
  const [options, setOptions] = useState([]);
  const [logoVertical, setLogoVertical] = useState();
  const [errores, setErrores] = useState([]);
  const [cambioExitoso, setCambioExitoso] = useState();

  useEffect(() => {
    traerAutoexclusion();
    cargarImgDinamica("VERTICAL_ORG");
  }, []);

  const traerAutoexclusion = () => {
    let url = process.env.REACT_APP_WS_GET_LISTA_AUTOEXCLUSION;
    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setOptions(json.parametros);
          setExcluido(json.autoexcluido);
          setDiasExclusion(json.dias);
          setFechaFin(formatDate(json.hasta));
        } else if (json.status === "error") {
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
      });
  };

  const cargarImgDinamica = (nombre) => {
    import(
      `./img/${nombre}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setLogoVertical(image.default);
    });
  };

  const formatDate = (string) => {
    let options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(string).toLocaleDateString("es-ES", options);
  };

  const confirmarAutoexclusion = (idOpcion) => {
    let url = process.env.REACT_APP_WS_GRABAR_AUTOEXCLUSION;
    url = url.replace(":idOpcion", idOpcion);

    let cerrarSesion = false;
    let statusCode = "";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setOptions(json.parametros);
          setExcluido(json.autoexcluido);
          setDiasExclusion(json.dias);
          setFechaFin(formatDate(json.hasta));
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS(
              "Confirmar Autoexclusion",
              json.errores,
              cerrarSesion
            );
          } else {
            setErrores(json.errores);
            setCambioExitoso(false);
            props.mensajeErrorWS("Confirmar Autoexclusion", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
      });
  };

  const clickBtnAutoexclusion = () => {
    let idOpcion = null;

    $("input:radio").each(function () {
      let $this = $(this);

      if ($this.is(":checked")) {
        idOpcion = $this.attr("id");
      }
    });

    let diasTexto = $("#" + idOpcion)
      .closest("td")
      .prev("td")
      .text();

    $.confirm({
      title: `<b class="op-text">Confirmar ${
        ["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
          ? "Autolimitación"
          : "Autoexclusión"
      }</b>`,
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<h5 class="text-center mt-4 mb-2">
          ¿Desea ${
            ["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
              ? "Autolimitarse"
              : "Autoexcluirse"
          } por ${diasTexto}?  
         </h5>`,
      buttons: {
        No: {
          text: "Cancelar",
          action: function () {},
        },
        Si: {
          text: "Aceptar",
          action: function () {
            confirmarAutoexclusion(idOpcion);
          },
        },
      },
    });
  };

  return (
    <Animated animationIn="fadeIn">
      <Mobile>
        <DeviceOrientation lockOrientation={"portait"}>
          <Orientation orientation="portrait" alwaysRender={false}>
            <div
              className="bg-light p-2 w-90 mx-auto rounded shadow mb-5 autoexclusionPanel"
              style={{ marginTop: "10.5rem" }}
            >
              <div
                className={
                  "text-light rounded text-center p-2 mb-3 bg-prov-" +
                  process.env.REACT_APP_COD_ORGANIZACION
                }
              >
                <h4>
                  {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                    ? "Autolimitación"
                    : "Autoexclusión"}
                </h4>
              </div>

              {excluido && !indefinido && (
                <div className="text-center mb-3">
                  <span>
                    Usted se encuentra{" "}
                    {["13", "43"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                      ? "autolimitado"
                      : "autoexcluido"}{" "}
                    por{" "}
                <b>{diasExclusion} dias</b> hasta el{" "}
                <b>{fechaFin.toString().substring(0, 25)}</b>{" "}
              </span>

                </div>
              )}

              {excluido && indefinido && (
                <div className="text-center">
                  <span>
                    Usted se encuentra{" "}
                    {["13", "43"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                      ? "autolimitado"
                      : "autoexcluido"}{" "}
                    <b>indefinidamente</b>
                  </span>
                </div>
              )}

              {options.length >= 1 && (
                <div>
                  <div className="text-center mb-3">
                    <b>
                      Elija periodo de{" "}
                      {["13", "43"].includes(
                        process.env.REACT_APP_COD_ORGANIZACION
                      )
                        ? "Autolimitación"
                        : "Autoexclusión"}
                    </b>
                  </div>

                  <div className="text-center mb-4">
                    <table className="table-fixed">
                      {options.map((opcion, index) => (
                        <tr>
                          <td id="dias" style={{ width: "200px" }}>
                            <label for={opcion.id}>{opcion.dias} dias</label>
                          </td>
                          <td>
                            <input
                              id={opcion.id}
                              name="option"
                              type="radio"
                              className="checkbox"
                              disabled={!opcion.seleccionable}
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  <div className="row text-center mt-3 mb-3">
                    <div className="col sm-4">
                      <Button
                        onClick={clickBtnAutoexclusion}
                        className="btn-info"
                      >
                        {["13", "43"].includes(
                          process.env.REACT_APP_COD_ORGANIZACION
                        )
                          ? "Autolimitar"
                          : "Autoexcluir"}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {options.length < 1 && (
                <div className="text-center p-2">
                  <h4>
                    Disculpe, la{" "}
                    {["13", "43"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                      ? "Autolimitación"
                      : "Autoexclusión"}{" "}
                    no se encuentra disponible en este momento
                  </h4>
                </div>
              )}
            </div>
          </Orientation>
          <Orientation orientation="landscape" alwaysRender={false}>
            {/*  <img
              style={{
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: "-.5rem",
                left: "0",
              }}
              src={this.state.logoVertical}
              id="logoIndia"
              className="mt-2"
            /> */}
          </Orientation>
        </DeviceOrientation>
      </Mobile>

      <Desktop>
        <div
          className="bg-light p-2 w-90 mx-auto rounded shadow mb-5 autoexclusionPanel"
          style={{ marginTop: "10.5rem" }}
        >
          <div
            className={
              "text-light rounded text-center p-2 mb-3 bg-prov-" +
              process.env.REACT_APP_COD_ORGANIZACION
            }
          >
            <h4>
              {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                ? "Autolimitación"
                : "Autoexclusión"}
            </h4>
          </div>

          {excluido && !indefinido && (
            <div className="text-center mb-3">
              <span>
                Usted se encuentra{" "}
                {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                  ? "autolimitado"
                  : "autoexcluido"}{" "}
                por <b>{diasExclusion} dias</b> hasta el{" "}
                <b>{fechaFin.toString().substring(0, 25)}</b>{" "}
              </span>
            </div>
          )}

          {excluido && indefinido && (
            <div className="text-center">
              <span>
                Usted se encuentra{" "}
                {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                  ? "autolimitado"
                  : "autoexcluido"}{" "}
                <b>indefinidamente</b>
              </span>
            </div>
          )}

          {options.length >= 1 && (
            <div>
              <div className="text-center mb-3">
                <b>
                  Elija periodo de{" "}
                  {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                    ? "Autolimitación"
                    : "Autoexclusión"}
                </b>
              </div>

              <div className="text-center mb-4">
                <table className="table-fixed">
                  {options.map((opcion, index) => (
                    <tr>
                      <td id="dias" style={{ width: "200px" }}>
                        <label for={opcion.id}>{opcion.dias} dias</label>
                      </td>
                      <td>
                        <input
                          id={opcion.id}
                          name="option"
                          type="radio"
                          className="checkbox"
                          disabled={!opcion.seleccionable}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <div className="row text-center mt-3 mb-3">
                <div className="col sm-4">
                <Button
                          onClick={clickBtnAutoexclusion}
                          className="btn-info"
                        >
                          {["13", "43"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                            ? "Autolimitar"
                            : "Autoexcluir"}
                        </Button>
                </div>
              </div>
            </div>
          )}
          {options.length < 1 && (
            <div className="text-center p-2">
              <h4>
                Disculpe, la{" "}
                {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                  ? "Autolimitación"
                  : "Autoexclusión"}{" "}
                no se encuentra disponible en este momento
              </h4>
            </div>
          )}
        </div>
      </Desktop>

      <Tablet>
        <div
          className="bg-light p-2 w-90 mx-auto rounded shadow mb-5 autoexclusionPanel"
          style={{ marginTop: "10.5rem" }}
        >
          <div
            className={
              "text-light rounded text-center p-2 mb-3 bg-prov-" +
              process.env.REACT_APP_COD_ORGANIZACION
            }
          >
            <h4>
              {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                ? "Autolimitación"
                : "Autoexclusión"}
            </h4>
          </div>

          {excluido && !indefinido && (
            <div className="text-center mb-3">
              <span>
              {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                    ? "autolimitado"
                    : "autoexcluido"}{" "}
                    por <b>{diasExclusion} dias</b>{" "}
                    hasta el <b>{fechaFin.toString().substring(0, 25)}</b>{" "}
              </span>
            </div>
          )}

          {excluido && indefinido && (
            <div className="text-center">
              <span>
                Usted se encuentra{" "}
                {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                  ? "autolimitado"
                  : "autoexcluido"}{" "}
                por <b>{this.state.diasExlusion} dias</b> hasta el{" "}
                <b>{this.state.fechaFin.substring(0, 25)}</b>{" "}
              </span>
            </div>
          )}

          {options.length >= 1 && (
            <div>
              <div className="text-center mb-3">
                <b>
                  Elija periodo de{" "}
                  {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                    ? "Autolimitación"
                    : "Autoexclusión"}
                </b>
              </div>

              <div className="text-center mb-4">
                <table className="table-fixed">
                  {options.map((opcion, index) => (
                    <tr>
                      <td id="dias" style={{ width: "200px" }}>
                        <label for={opcion.id}>{opcion.dias} dias</label>
                      </td>
                      <td>
                        <input
                          id={opcion.id}
                          name="option"
                          type="radio"
                          className="checkbox"
                          disabled={!opcion.seleccionable}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>

              <div className="row text-center mt-3 mb-3">
                <div className="col sm-4">
                <Button
                      onClick={clickBtnAutoexclusion}
                      className="btn-info"
                    >
                      {["13", "43"].includes(
                        process.env.REACT_APP_COD_ORGANIZACION
                      )
                        ? "Autolimitar"
                        : "Autoexcluir"}
                    </Button>
                </div>
              </div>
            </div>
          )}
          {options.length < 1 && (
            <div className="text-center p-2">
              <h4>
                Disculpe, la{" "}
                {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                  ? "Autolimitación"
                  : "Autoexclusión"}{" "}
                no se encuentra disponible en este momento
              </h4>
            </div>
          )}
        </div>
      </Tablet>
    </Animated>
  );
};

export default Autoexclusion;
