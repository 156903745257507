import React from "react";

const ModalNotificaciones = ({ closeNotificacion, mensajesGenerales }) => {
  return (
    <div
      style={{ position: "fixed", top: "8%" }}
      className="modal fade"
      id="notiModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content pb-2">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <span
                className="iconify mr-2 mb-1"
                data-icon="bytesize:bell"
                data-inline="false"
                data-width="24px"
              ></span>
              Notificaciones
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeNotificacion}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
            className="modal-body"
            style={{ maxHeight: "300px", overflow: "scroll" }}
          >
            <table className="table table-striped text-center text-small">
              <tbody>
                {mensajesGenerales.map((mensaje, index) => (
                  <tr
                    className={
                      mensaje.visto === false
                        ? "font-weight-bold"
                        : "bg-lightgray text-muted"
                    }
                    key={index}
                  >
                    <td>
                      {mensaje.fechaHora.substring(0, 10)}
                      <br></br>
                      {mensaje.fechaHora.substring(10, 16)}
                    </td>
                    <td className="pl-3 pr-3">{mensaje.texto}</td>
                  </tr>
                ))}

                {mensajesGenerales.length === 0 && (
                  <tr className="bg-lightgray text-muted">
                    <td className="pl-3 pr-3">
                      Aún no ha recibido notificaciones
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNotificaciones;
