import React, { useEffect, useState } from "react";
import $ from "jquery";
import "jquery-confirm/css/jquery-confirm.css";
import "jquery-confirm";
import { useHistory, useParams, withRouter } from "react-router-dom";

const Juego = (props) => {
  const [urlIframeJuego, seturlIframeJuego] = useState(undefined);
  const { nombrejuego } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (props.estadoPlataforma.estado === "PLATAFORMA") {
      history.push("/home");
    }
    console.log(props.estadoPlataforma);
  });

  useEffect(() => {
    switch (nombrejuego) {
      case "Quinielatradicional":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_TOMBOLA);
        break;
      case "Quiniela Instantánea":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS);
        break;
      case "quinielainstantanea":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS);
        break;
      case "patagoniaminibingo":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO);
        break;
      case "patagoniatelebingo":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO);
        break;
      case "Tombola":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_TOMBOLA);
        break;
      case "QuiniExpress":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS);
        break;
      case "TomboExpress":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS);
        break;

      default:
        break;
    }
  }, []);

  const cargoElIFrame = () => {
    props.terminoCargaJuego();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.arrancarJuego(urlIframeJuego);
  }, []);

  return props.estadoPlataforma.estado === "PLATAFORMA" ? (
    <></>
  ) : (
    <iframe
      onLoad={cargoElIFrame}
      src={urlIframeJuego}
      style={{ visibility: "visible" }}
      title="juego"
      id="zonaJuego"
      frameBorder="0"
    ></iframe>
  );
};

export default withRouter(Juego);
