import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { estilosProv } from "./coloresProvincias";
import $ from "jquery";

const SideBarMenu = (props) => {
  const codOrganizacion = process.env.REACT_APP_COD_ORGANIZACION;
  const colorFondo = estilosProv[codOrganizacion].fondo;
  const colorFont = estilosProv[codOrganizacion].fontColor;

  return (
    <DivFondo aplicarGradiente={false} color={colorFondo}>
      {props.logueado === true && (
        <div>
          <div>
            <DivIcono onClick={() => props.volverAPlataforma()}>
              <Link exact={"true"} activeclassname="is-active" to="/home">
                <ColorIcono
                  colorFont={colorFont}
                  className="iconify"
                  data-icon="bytesize:home"
                  data-inline="false"
                  data-width="23px"
                ></ColorIcono>
                <div>
                  <ColorTextIcono colorFont={colorFont}>Inicio</ColorTextIcono>
                </div>
              </Link>
            </DivIcono>

            <DivIcono>
              <Link
                to="/consultaBilletera"
                onClick={() => {
                  props.volverAPlataforma();
                  props.consultarBilletera();
                }}
              >
                <ColorIcono
                  colorFont={colorFont}
                  className="iconify"
                  data-icon="ion:wallet-outline"
                  data-inline="false"
                  data-width="25px"
                ></ColorIcono>
                <div>
                  <ColorTextIcono colorFont={colorFont}>
                    Billetera
                  </ColorTextIcono>
                </div>
              </Link>
            </DivIcono>

            <DivBtnNewGame
              className="BtnNewGame"
              data-toggle="modal"
              data-target="#newGameModalDesktop"
              id="BtnNewGameDesktop123"
            >
              <div>
                <SpanBtnNewGame
                  colorFont={colorFont}
                  className="iconify"
                  data-icon="fluent:lottery-24-regular"
                  data-inline="false"
                  data-width="40px"
                ></SpanBtnNewGame>
              </div>
            </DivBtnNewGame>

            <DivIcono onClick={() => props.volverAPlataforma()}>
              <Link to="/identificadorQRCliente">
                <ColorIcono
                  colorFont={colorFont}
                  className="iconify"
                  data-icon="uil:money-withdrawal"
                  data-inline="false"
                  data-width="25px"
                ></ColorIcono>
                <div>
                  <ColorTextIcono colorFont={colorFont}>Retirar</ColorTextIcono>
                </div>
              </Link>
            </DivIcono>

            <DivIcono onClick={() => props.volverAPlataforma()}>
              <Link to="/misDatosPersonales">
                <ColorIcono
                  colorFont={colorFont}
                  className="iconify"
                  data-icon="bi:person"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="25px"
                ></ColorIcono>
                <div>
                  <ColorTextIcono colorFont={colorFont}>Usuario</ColorTextIcono>
                </div>
              </Link>
            </DivIcono>
          </div>
        </div>
      )}
    </DivFondo>
  );
};

export default SideBarMenu;

const DivFondo = styled.div`
  background-color: ${(props) => props.color};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 2px 3px 9px 1px #858f8a" : ""};
  width: 6rem;
  position: absolute;
  top: 30vh;
  left: 0;
  z-index: 2 !important;
  border-bottom-right-radius: 2em;
  border-top-right-radius: 2em;
`;

const DivIcono = styled.div`
  font-size: small;
  margin: 1rem 0rem 1rem 0rem;
  text-align: center;
`;

const ColorIcono = styled.span`
  color: ${(props) => props.colorFont};
`;
const ColorTextIcono = styled.span`
  color: ${(props) => props.colorFont};
`;

const SpanBtnNewGame = styled.span`
  color: black;
  background-color: white;
  border-radius: 100%;
  width: 3rem;
  height: auto;
`;

const DivBtnNewGame = styled.div`
  color: white;
  text-align: center;
`;
