export const instalaciones = {
  41: {
    titulo: "Loteria de Santa Cruz Online",
    shortName: "loteriadesantacruz",
    color: "#662483",
    canonical: "https://sc.lotline.com.ar/plataforma/",
    manifest: "manifestSC.webmanifest",
    direccion: "Perito Moreno esquina Zapiola, Río Gallegos",
  },
  11: {
    titulo: "Loteria de Santa Cruz Online",
    shortName: "loteriadesantacruz",
    color: "#662483",
    canonical: "https://sc.lotline.com.ar/plataforma/",
    manifest: "manifestSC.webmanifest",
    direccion: "Perito Moreno esquina Zapiola, Río Gallegos",
  },
  42: {
    titulo: "La Neuquina Online",
    shortName: "laneuquinaonline",
    color: "#FFCC00",
    canonical: "https://www.laneuquinaonline.com.ar/plataforma/",
    manifest: "manifestNQN.webmanifest",
    direccion: "Carlos H. Rodríguez 146, Neuquén",
    urlTelebingo:
      "https://issuu.com/arteymania/docs/manual_apostador__2018_-_corregido_/24",
  },
  12: {
    titulo: "La Neuquina Online",
    shortName: "laneuquinaonline",
    color: "#FFCC00",
    canonical: "https://www.laneuquinaonline.com.ar/plataforma/",
    manifest: "manifestNQN.webmanifest",
    direccion: "Carlos H. Rodríguez 146, Neuquén",
    urlTelebingo:
      "https://issuu.com/arteymania/docs/manual_apostador__2018_-_corregido_/24",
  },
  53: {
    titulo: "Loteria de Salta Online",
    shortName: "loteriadesalta",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifestSalta.webmanifest",
    direccion: "Balcarce 759, Salta",
  },
  23: {
    titulo: "Loteria de Salta Online",
    shortName: "loteriadesalta",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifestSalta.webmanifest",
    direccion: "Balcarce 759, Salta",
  },
  43: {
    titulo: "La Rionegrina Online",
    shortName: "larionegrinaonline",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifestSalta.webmanifest",
    direccion:
      "Sede Central: 25 de Mayo y Sarmiento (8500) - Viedma - Río Negro",
  },
  13: {
    titulo: "La Rionegrina Online",
    shortName: "larionegrinaonline",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifestSalta.webmanifest",
    direccion:
      "Sede Central: 25 de Mayo y Sarmiento (8500) - Viedma - Río Negro",
  },
};
