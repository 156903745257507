export const estilosProv = {
  41: {
    fondo: "#0046ac",
    fontColor: "#f0f0f0",
    fondoLlamador: "#0046ac",
    gradiente1Llamador: "#7f9be9",
    gradiente2Llamador: "#2122411f",
  },
  11: {
    fondo: "#0046ac",
    fontColor: "#f0f0f0",
    fondoLlamador: "#0046ac",
    gradiente1Llamador: "#7f9be9",
    gradiente2Llamador: "#2122411f",
  },
  42: {
    fondo: "#ffcc00",
    fontColor: "#5c5c5c",
    fondoLlamador: "#ffcc00",
    gradiente1Llamador: "#ffeea1",
    gradiente2Llamador: "#2122411f",
  },
  12: {
    fondo: "#ffcc00",
    fontColor: "#5c5c5c",
    fondoLlamador: "#ffcc00",
    gradiente1Llamador: "#ffeea1",
    gradiente2Llamador: "#2122411f",
  },
  53: {
    fondo: "#5601b7",
    fontColor: "#f0f0f0",
    fondoLlamador: "#5601b7",
    gradiente1Llamador: "#a56ee1",
    gradiente2Llamador: "#2122411f",
  },
  23: {
    fondo: "#5601b7",
    fontColor: "#f0f0f0",
    fondoLlamador: "#5601b7",
    gradiente1Llamador: "#bb4d81",
    gradiente2Llamador: "#2122411f",
  },
  43: {
    fondo: "#01C160",
    fontColor: "#f0f0f0",
    fondoLlamador: "#19193a",
    gradiente1Llamador: "#5b71c5bd",
    gradiente2Llamador: "#2122411f",
  },
  13: {
    fondo: "#01C160",
    fontColor: "#f0f0f0",
    fondoLlamador: "#19193a",
    gradiente1Llamador: "#5b71c5bd",
    gradiente2Llamador: "#2122411f",
  },
};
