import React from "react";
import { useState } from "react";
import $ from "jquery";
import { Button, Form, FormGroup } from "react-bootstrap";
import Input from "reactstrap/lib/Input";
import { useEffect } from "react";

const CambiarClave = (props) => {
  const [clave, setclave] = useState("");
  const [nuevaClave, setnuevaClave] = useState("");
  const [confirmaNuevaClave, setconfirmaNuevaClave] = useState("");
  const [modificando, setmodificando] = useState("INICIO");
  const [errores, seterrores] = useState("");
  const [errors, seterrors] = useState({
    clave: "",
    nuevaClave: "",
    confirmaNuevaClave: "",
  });

  useEffect(() => {
    handleChange("clave");
  }, [clave]);

  useEffect(() => {
    handleChange("nuevaClave");
  }, [nuevaClave]);

  useEffect(() => {
    handleChange("confirmaNuevaClave");
  }, [confirmaNuevaClave]);

  const handlePassView = () => {
    let x = $("#clave");

    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
      $("#eye").removeClass("fa-eye-slash");
      $("#eye").addClass("fa-eye");
    } else {
      x.addClass("text-pass");
      $("#eye").removeClass("fa-eye");
      $("#eye").addClass("fa-eye-slash");
    }
  };

  const handlePassView2 = () => {
    let x = $("#nuevaClave");
    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
      $("#eye2").removeClass("fa-eye-slash");
      $("#eye2").addClass("fa-eye");
    } else {
      x.addClass("text-pass");
      $("#eye2").removeClass("fa-eye");
      $("#eye2").addClass("fa-eye-slash");
    }
  };

  const handlePassView3 = () => {
    let x = $("#confirmaNuevaClave");
    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
      $("#eye3").removeClass("fa-eye-slash");
      $("#eye3").addClass("fa-eye");
    } else {
      x.addClass("text-pass");
      $("#eye3").removeClass("fa-eye");
      $("#eye3").addClass("fa-eye-slash");
    }
  };

  const handleClickBorrarCampos = (event) => {
    $("#clave").val("");
    $("#nuevaClave").val("");
    $("#confirmaNuevaClave").val("");

    let limpiarErrors = {
      clave: "",
      nuevaClave: "",
      confirmaNuevaClave: "",
    };

    seterrors(limpiarErrors);
  };

  const handleChange = (name) => {
    switch (name) {
      case "clave":
        clave.length < 8
          ? seterrors({
              ...errors,
              clave: "La clave debe contener al menos 8 caracteres",
            })
          : seterrors({ ...errors, clave: "" });
        break;
      case "nuevaClave":
        nuevaClave.length < 8
          ? seterrors({
              ...errors,
              nuevaClave: "La clave debe contener al menos 8 caracteres",
            })
          : seterrors({ ...errors, nuevaClave: "" });
        break;
      case "confirmaNuevaClave":
        nuevaClave !== confirmaNuevaClave
          ? seterrors({
              ...errors,
              confirmaNuevaClave: "Nueva clave y su confirmación no coinciden",
            })
          : seterrors({ ...errors, confirmaNuevaClave: "" });
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let valid = true;
    if (clave === "") {
      seterrors({
        ...errors,
        clave: "La clave debe contener al menos 8 caracteres",
      });
    }
    if (nuevaClave === "") {
      seterrors({
        ...errors,
        nuevaClave: "La clave debe contener al menos 8 caracteres",
      });
    }

    if (confirmaNuevaClave === "") {
      seterrors({
        ...errors,
        confirmaNuevaClave: "Nueva clave y su confirmación no coinciden",
      });
    }

    let i = 0;
    let nombresAtributos = Object.keys(errors);
    let errores = Object.values(errors);
    for (i = 0; i < errores.length; ++i) {
      if (errores[i].length > 0) {
        valid = false;
        $("#" + nombresAtributos[i]).focus();
        break;
      }
    }
    return valid;
  };

  const cambiarClave = () => {
    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      password: clave,
      newPassword: nuevaClave,
      matchingPassword: confirmaNuevaClave,
    };

    let url = process.env.REACT_APP_WS_CAMBIAR_CLAVE;

    let cerrarSesion = false;
    let statusCode = "";

    setmodificando("EJECUTANDO");

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          seterrores([]);
          props.cambioClaveExitoso(true);
          setmodificando("FIN");
          props.handleRemember("erase");
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS("Cambiar clave", json.errores, cerrarSesion);
          } else {
            seterrores(json.errores);
            props.cambioClaveExitoso(false);
            props.mensajeErrorWS("Cambiar clave", json.errores);
            setmodificando("FIN");
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        setmodificando("FIN");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      cambiarClave();
    }
  };

  return (
    <div>
      {props.cambioExitoso === true && (
        <div className="bg-light text-center p-2 mt-4 mb-2">
          <div className="bg-success text-light p-2 rounded mb-3 mt-2">
            <h4 className="mb-2">Cambio Exitoso </h4>
          </div>
          <p>
            Recuerde que la próxima vez que ingrese al sistema deberá utilizar
            la nueva clave.
          </p>
        </div>
      )}

      {props.cambioExitoso === false && (
        <div>
          <Form onSubmit={handleSubmit} noValidate>
            <FormGroup className="mt-4">
              <div className="text-left op-text" style={{ fontSize: "12px" }}>
                Contraseña actual
              </div>
              <div className="input-group">
                <Input
                  type="text"
                  autoComplete="off"
                  className="text-pass"
                  name="clave"
                  id="clave"
                  placeholder=""
                  onChange={(e) => setclave(e.target.value)}
                />
                <div
                  onClick={handlePassView}
                  className="text-center border"
                  style={{ width: "15%" }}
                >
                  <i
                    className="fa fa-eye-slash mt-2"
                    aria-hidden="true"
                    id="eye"
                  ></i>
                </div>
              </div>
            </FormGroup>
            <span
              className="text-danger text-small"
              style={{ position: "relative", top: "-15px" }}
            >
              {errors.clave}
            </span>

            <hr />

            <FormGroup>
              <div className="text-left op-text" style={{ fontSize: "12px" }}>
                Nueva contraseña
              </div>
              <div className="input-group">
                <Input
                  type="text"
                  autoComplete="off"
                  className="text-pass"
                  name="nuevaClave"
                  id="nuevaClave"
                  placeholder=""
                  onChange={(e) => setnuevaClave(e.target.value)}
                />
                <div
                  onClick={handlePassView2}
                  className="text-center border"
                  style={{ width: "15%" }}
                >
                  <i
                    className="fa fa-eye-slash mt-2"
                    aria-hidden="true"
                    id="eye2"
                  ></i>
                </div>
              </div>
            </FormGroup>
            <span
              className="text-danger text-small"
              style={{ position: "relative", top: "-15px" }}
            >
              {errors.nuevaClave}
            </span>

            <FormGroup>
              <div className="text-left op-text" style={{ fontSize: "12px" }}>
                Confirmar nueva contraseña
              </div>
              <div className="input-group">
                <Input
                  type="text"
                  autoComplete="off"
                  className="text-pass"
                  name="confirmaNuevaClave"
                  id="confirmaNuevaClave"
                  placeholder=""
                  onChange={(e) => setconfirmaNuevaClave(e.target.value)}
                />
                <div
                  onClick={handlePassView3}
                  className="text-center border"
                  style={{ width: "15%" }}
                >
                  <i
                    className="fa fa-eye-slash mt-2"
                    aria-hidden="true"
                    id="eye3"
                  ></i>
                </div>
              </div>
            </FormGroup>
            <span
              className="text-danger text-small"
              style={{ position: "relative", top: "-15px" }}
            >
              {errors.confirmaNuevaClave}
            </span>

            <p className="text-small text-justify op-text">
              La clave debe contener al menos 8 caracteres, una letra mayúscula,
              una letra minúscula y un número
            </p>
            <div className="row mt-4 mb-3 text-center">
              <div className="col sm-12">
                <Button
                  color="info"
                  className="btn-retiro"
                  onClick={handleSubmit}
                >
                  {modificando === "EJECUTANDO" && (
                    <div className="animate-flicker">
                      <b>Modificando ...</b>
                    </div>
                  )}

                  {(modificando === "INICIO" || modificando === "FIN") && (
                    <span>MODIFICAR</span>
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default CambiarClave;
